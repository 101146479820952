<template>
  <div class="card">
    <ul>
      <li v-for="(item,index) in treeData" :key="index">
        <div class="item" :class="{'line-left':index !== 0, 'line-right': index != treeData.length-1}">
          <div class="item-name" :class="{'line-bottom': item.flowPath&&item.flowPath.length>0, 'line-top': !treeFirst, 
                'm': item.nodeType == 'M', 
                's': item.nodeType == 'S',
                'c': item.nodeType == 'C'}">
            <p>{{item.compType == 'A' ? '承兑人': '持票人'}}：{{item.compName}}</p>
            <p>票面金额：{{item.packageSum}}元</p>
            <p>出票日期：{{item.applyDate}}</p>
            <p>子票区间：{{item.ticketRange}}</p>
          </div>
        </div>
        <tree-data v-if="item.flowPath&&item.flowPath.length>0" :tree-data="item.flowPath" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'TreeData',
  props: ['treeData', 'treeFirst'] 
}
</script>

<style lang="scss" scoped>
$line-length:20px; //线长
$spacing:20px; //间距
$extend:calc(#{$spacing} + 2px); //延长线

// 线样式
@mixin line{
    content: "";
    display: block;
    width: 3px;
    height: $line-length;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    background: #E0E0E0;
}
// 加减号icon
@mixin icon{
  position: absolute;
  border: solid 1px #666;
  border-radius: 100%;
  bottom:-5px;
  width: 12px;
  height: 12px;
  line-height: 10px;
  text-align: center;
  color:#666;
  z-index: 100;
  cursor: pointer;
  margin: 0;
  padding: 0;
  // opacity: 0;
}

.card{
    ul{
      display: flex;
      justify-content: center;
      li{
        list-style: none;
        .item{
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          &-name{
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 10px;
            margin: $spacing;
            color: #fff;
            p{
              max-width: 260px;
              line-height: 20px;
              font-size: 12px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            .reduce{
              @include icon();
              top:-5px;
              &:hover{
                opacity: 100%;
              }
            }
          }
          .m{
            background-color: #588AF2;
          }
          .s{
            background-color: #57D1A0;
          }
          .c{
            background-color: #F0B814;
          }
        }
      }
    }
    // 向下的线
    .line-bottom{
      &::after{
        @include line();
        bottom: -$line-length;
      }
    }
    // 向上的线
    .line-top{
      &::before{
        @include line();
        top: -$line-length;
      }
    }
    // 向左的线
    .line-left{
      &::after{
        @include line();
        width: calc(50% + #{$spacing});
        height: 3px;
        left: calc(-50% - #{$extend});
        top: 0;
      }
    }
    // 向右的线
    .line-right{
      &::before{
        @include line();
        width: calc(50% + #{$spacing});
        height: 3px;
        right: calc(-50% - #{$extend});
        top: 0;
      }
    }
}
</style>
