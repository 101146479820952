<template>
  <div class="tree">
    <!-- 左侧描述 -->
    <tree-describe :packageNo="packageNo" :ticketRange="ticketRange" :bizDescList="bizDescList"></tree-describe>
    <!-- 右侧树结构 -->
    <tree-data :treeData="ticketFlowPath" :tree-first="true"></tree-data>
  </div>
</template>


<script>
  // 左侧描述
  import TreeDescribe from './children/TreeDescribe'
  // 右侧树结构
  import TreeData from './children/TreeData.vue'
  export default {
    name: 'FlowPath',
    components: {
      TreeDescribe,
      TreeData,
    },
    mounted(){
      // 票据id	
      this.ticketId = this.$route.query.ticketId
      this.packageNo = this.$route.query.packageNo
      // 获取数据
      this.getInfo()
    },
    data(){
      return {
        ticketId: '', // 票据id	
        packageNo: '',  //票据包号	
        ticketRange: '', //子票区间	
        bizDescList: '', //业务描述	
        ticketFlowPath: '', // 流转轨迹树	
      }
    },
    methods:{
      // 获取数据
      async getInfo(){
        const {data: res} = await this.$auth.get(`/cpiaoju-ticket/debt/ticket/flowPath/${this.ticketId}/${this.packageNo}`)
        console.log(res);
        if(res.code !== 200) return this.$message.error(res.msg)
        this.packageNo = res.data.packageNo
        this.ticketRange = res.data.ticketRange
        this.bizDescList = res.data.bizDescList
        this.ticketFlowPath = res.data.flowPath
      }
    }
  }
</script>

<style lang="scss" scoped>
  ul,li{
    list-style: none;
    padding: 0;
    margin: 0;
  }
.tree{
    background: #fff;
    min-height: calc(100vh - 110px);
    position: relative;
    display: flex;
    justify-content: space-between;
    &-content{
      background: #fff;
      position: absolute;
      top: 0;
      left: 0;
    }
}

</style>
