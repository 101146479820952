<template>
  <div class="tree-describe">
    <h3 class="title">应付票据流转轨迹</h3>
    <p>票据包号：{{packageNo}}</p>
    <p>子票区间：{{ticketRange}}</p>
    <div class="tree-describe-show">
      <span><i class="c1"></i> 母票</span>
      <span><i class="c2"></i> 子票</span>
      <span><i class="c3"></i> 当前</span>
    </div>
    <ul class="tree-describe-list">
      <li v-for="(item, index) in bizDescList" :key="index">
        {{item}}
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'TreeDescribe',
    props: ['packageNo', 'ticketRange', 'bizDescList']
  }
</script>

<style lang="less" scoped>
  .tree-describe{
    width: 40%;
    padding-top: 50px;
    padding-left: 40px;
    box-sizing: border-box;
    .title{
      line-height: 30px;
      font-size: 16px;
    }
    p{
      font-size: 12px;
      margin-top: 10px;
    }
    .tree-describe-show{
      width: 260px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      span{
        display: flex;
        align-items: center;
        i{
          display: block;
          width: 10px;
          height: 10px;
          margin-right: 10px;
        }
        .c1{
          background-color: #588AF2;
        }
        .c2{
          background-color: #57D1A0;
        }
        .c3{
          background-color: #F0B814;
        }
      }
    }
    .tree-describe-list{
      margin-top: 30px;
      li{
        line-height: 20px;
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }
  
</style>